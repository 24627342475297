// bootstrap all global dependencies

import 'material-icons/iconfont/material-icons.css'

// consolidate all existing css files
import '~/skin/css/jquery-ui-1.8.2.custom.css'
import '~/skin/css/ui.jqgrid.css'
import '~/skin/selectize/css/selectize.bootstrap3.css'
import '~/skin/selectize/css/selectize.css'

import 'jquery'
import 'jquery-form'
import 'webpack-jquery-ui'
import '@selectize/selectize'

import 'preline/dist/preline.js'
import DataTable from 'datatables.net-dt'
window.DataTable = DataTable

import AirDatepicker from 'air-datepicker'
import localeEn from 'air-datepicker/locale/en.js'
window.AirDatepicker = AirDatepicker
window.localeEn = localeEn

// jqgrid ui
import '~/skin/js/jquery.jqGrid.min'
import '~/skin/js/i18n/grid.locale-en'
import '~/skin/js/grid.setcolumns'

// used in remembering last set grid settings
// which are typically in goods in and goods out summary pages
import * as store from '~/skin/js/store.min'
window.store = store

// whs
import '~/skin/js/whs'

// fancybox
import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'

// execute scripts...
(function ($) {
    // after window 'load'
    $(window).on('load', () => {
        // bind data-fancybox
        Fancybox.bind('[data-fancybox]')
    })
})(jQuery)
